body{
    font-family: 'Montserrat', sans-serif;
}

#carouselExampleIndicators{
    margin-bottom:30px;
}
.carousel-indicators{
    bottom:-20px;
    margin-bottom: 0;
    li{
        width:10px;
        height:10px;
        border:none;
        border-radius: 50px;
        background-color:#343a40;
    }
}

.bg-dark{
    background-color:#000!important;
}

.bg-orange{
    background-color:#d3530f;
}
.bg-green{
    background-color:#26b600;
    &:hover{
        background-color:#1f8f00;
    }
}

img{
    max-width:100%;
}

footer {
    position: relative;
    ul{
        li  {
            a{
                display:block;

            }
        }
    }

    a{
        color:#5e5e5e;
        &:hover{
            color:black;
        }
    }
    .container-fluid {
        padding:0 25px;
    }
}


.mobil-menu{
    background-color:#f5f5f5;
    
    height: 100%;
    background: #fff;
    color: #373737;

    position: fixed;
    top: 0;
    left: 0;
    visibility: hidden;
    width: calc(100vw - 65px);
    height: 100vh;
    max-height: none;
    z-index: 20;
    transition: transform 0.5s cubic-bezier(0.645, 0.045, 0.355, 1),visibility 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
    background: #fff;
    box-shadow: none;
    touch-action: manipulation;
    transform: translateX(calc(-100vw + 65px));

    &.active{
        visibility: visible;
        transform: translateX(0);
    }

    .list{

        flex: 1 1 auto;
        a{
            display:block;
            color:#343a40;
            padding:17px 0px 15px 0;
            text-decoration: none;
            font-size:17px;
            letter-spacing: 3px;
            font-weight: 600;
            border-bottom:solid #dadada 1px;
            &:hover{
                color:#275f33;
            }
        }
    }
    .social-media{

        border-top:solid #dadada 1px;
        a{
            color:#9b9b9b;
            padding:20px;

        }
    }

}

.masaustu-menu {

    a{
        display: block;
        color:#343a40;
        text-decoration: none;

        padding:0 10px;
        letter-spacing: 3px;
        font-weight:600;
    }
}
.fas.fa-star{color:#e6af3c; font-size:10px;}

.sosyal-medya{
    a{
        text-decoration: none;
        color:#212120;
        font-size:24px;
        padding:0.8rem;
        transition:all 0.2s ease;
        &:hover{
            color:#366747;
        }
    }
}

.footer-menu{
    a{
        padding:0.5em 0;
        font-weight: 600;
        color:#5e5e5e;
        font-size:14px;
    }
}

a{
    color:inherit;
    &:hover,
    &:active{
        color:inherit;
        text-decoration: none;
    }
}

.w-initial {
   width:initial !important;
}

@media (min-width: 768px) {
    .w-md-initial {
       width:initial!important;
    }
    .w-md-100 {
       width:100% !important;
    }
    footer{

        .container-fluid {
            padding:0 75px;
        }
    }

}

html, body, .wrapper {height: 100%;}
body > .wrapper {height: auto; min-height: 75%;}

.bg-layer{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 800%;
    height: 100%;
    background-color: #F8F8F8;
    z-index: -1;
}
.live-chat-btn{
    font-size:20px;
    font-weight:700;

    color:#366747; 
    
    border:solid #366747 3px; 
    text-decoration:none;
    transition:all 0.2s ease;

    &:hover{
        background-color:#366747;
        //43bf6e
        color:white;
    }
}

.contact-form input{
    height:47px;
}

@media (min-width: 1200px) {
    .container-sm{
        max-width:720px;
    }
}

.get-started-btn{
    background-color:#c8355d; color:white; font-weight:700; line-height:44px; font-size:20px; padding:0 35px;
    display: -ms-inline-flexbox!important;
    display: inline-flex!important;
    border:none;
    letter-spacing: 0; padding-top:3px;
    width:100%;
    -ms-flex-pack: center!important;
    justify-content: center!important;

    &:hover,
    &:active{
        color:white;
    }
}

.baslik-yesil{
    font-family:'Metropolis Light';
    font-size:39px; 
    line-height:34px;
    color:#275f33; 
    text-align:center;
}
.product-detail-block-3-style{

    background-image: linear-gradient(#ffffff, #e9feea); 
    h3{

    }
    p{
        line-height:30px;
        text-align: justify; /* For Edge */
        font-size:17px;

    }
}
.product-detail-block-4-style{

    h3{
        text-align:left;
        font-size:18px;
        color:#275f33;
        font-family:"Metropolis Bold",sans-serif;
        margin-bottom: 20px;
    }
    p{
        line-height:27px;
        text-align: justify; /* For Edge */
        font-size:17px;
        color:#515053;
        font-family:"Metropolis RegularItalic",sans-serif;
        &.isim{
            font-family:"Metropolis MediumItalic",sans-serif;
        }
    }
}


.product-detail-block-5-style{

    .list {
        color:white;

        > * {
            .item {
                background-color:#275f33;
                border:solid #d06883 2px;
                padding:30px;
                font-size:18px;
                margin-bottom: 30px;
    
                img{
                    margin-bottom:20px;
                }
    
                .baslik{
                    font-weight: bold;
                } 
            }

        &:last-child{
            .item{
                margin-bottom: 0px;
                
            }    
        }
        } 
        
    }

}

.howtouse{

    .list {
        color:#3e3d3d;

        > * {
            .item {
                
                border:solid #139547 1px;
                border-radius: 10px;
                padding:10px 15px 40px 15px;
                font-size:15px;
                margin-bottom: 30px;

                .step{
                    font-weight:700; color:#139446; 
                    margin-bottom:20px;
                }
    
                img{
                    
                }
    
                .baslik{
                    color:#3e3d3d;
                    
                    font-weight:700;
                    padding-left:15px;
                    padding-right:0;
                    letter-spacing: -1px;
                } 
                
            }

        &:last-child{
            .item{
                margin-bottom: 0px;
                
            }    
        }
        } 
        
    }

}
.ingredients{
    .header-title{
        font-weight:800; color:#1e1e1e;
        font-size:28px;
    }
    ul{
        li{
            margin:0px 5px;
            font-size:14px;
            color:#3f3e3e;
            &:before {
                content:'';
                width:7px;
                height:7px;
                background-color:#0a0a0a;
                border-radius:100%;
                display:inline-flex;
                margin-right:5px;
            }
        }
    }

    .list {
        color:#3e3d3d;

        > * {
            .item {
                
                background-color: white;
                padding:10px 20px 40px 20px;
                font-size:12px;
                color:#686868;
                margin-bottom: 30px;

                .step{
                    font-weight:700; 
                    color:#1e1e1e;
                    font-size:22px; 
                    margin-bottom:5px;
                    
                }
    
                img{
                    width:70%;
                    margin-top:-30px;
                    margin-bottom:-10px;
                }
    
                .title{
                    font-size:15px;
                    color:#3f3e3e;
                    margin-bottom: 7px;
                    font-weight:600; 
                    letter-spacing: 0;

                }
                .paragraph{
                    color:#686868;
                    line-height: 15px;
                    padding-left:0;
                } 
                
            }

        &:last-child{
            .item{
                margin-bottom: 0px;
                
            }    
        }
        } 
        
    }

}

.product-detail-block-6-style{

    .flat-background{
        background-color:#353535;
        font-family: "Metropolis Bold",sans-serif;
        line-height: 38px;
        font-size:30px;

        .title{
            text-align:center;
            color:white;
    
        }
    }

    .list {
        //color:white;

        > * {
            .item {
                border:solid #e8e8e8 5px;
                padding:30px;
                margin-bottom: 30px;
                

                .title{
                    text-align:center;
                    font-size:31px;
                    color:#d91c5c;
                }
                
                .description{
                    text-align: center;
                    font-size:17px;
                    color:#666;
                }

                .new-price{
                    font-size:29px;
                    font-weight:700;
                    padding:20px 0 10px 0;
                    margin:0 10px;
                }

                .old-price{
                    font-size:19px;
                    color:#cbc7c7;
                    font-weight:500;
                    text-decoration: line-through;
                    padding-top: 13px;
                }

                .note{
                    font-size:19px;
                    color:#c8355d;
                    text-align: center;
                    min-height: 56px;
                    display: -ms-flexbox!important;
                    display: flex!important;
                    -ms-flex-pack: center!important;
                    justify-content: center!important;   
                    -ms-flex-align: center!important;
                    align-items: center!important;
                }

                .get-started-btn{
                    width:100%;
                    margin-bottom: 15px;
                    text-align: center;
                    -ms-flex-pack: center!important;
                    justify-content: center!important;          
                }

            }

        &:last-child{
            .item{
                margin-bottom: 0px;
                
            }    
        }
        } 
        
    }

    .icons{
        img{ max-height: 100%;
        }
        .title{
            color:#275f33;
            font-size:17px;
            font-family: "Metropolis Light",sans-serif;
        }
        .image{
            height:150px;
            display: -ms-flexbox!important;
            display: flex!important;
            -ms-flex-pack: center!important;
            justify-content: center!important;   
            -ms-flex-align: center!important;
            align-items: center!important;
        }
    }
   

}
.icons-pink{
    > *{
        padding:0 0px;
    }

    img{ max-height: 100%;
    }
    .title{
        color:#cf325b;
        font-size:14px;
        font-weight:800;
        padding-top:10px;
    }
    .image{
        height:60px;
        display: -ms-flexbox!important;
        display: flex!important;
        -ms-flex-pack: center!important;
        justify-content: center!important;   
        -ms-flex-align: center!important;
        align-items: center!important;
    }

}

.page-overlay{
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 10;
    visibility: hidden;
    opacity: 0;
    background: #363636;
    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
    &.active{
        opacity: 0.5;
        visibility: visible;
    }
}

.myacc_header{
    padding: 16px 20px;
    font-size:18px;
    font-weight:600;
    display: -ms-flexbox;
    display: flex;
    
    &.collapsed{

    }

    &.collapsed:after{
        content: '\f107';

    }


    &:after{
        content: '\f106';
        font-family:"Font Awesome 5 Free";
        font-weight: 900;        
        font-size:20px;
        margin-left:auto;
        
    }
        
}
.myacc_content{
    .card{

    }
    .card-body{
        padding:0px 20px 16px 20px;
        border:none;
    }
}   

.cover-background{
    background-color: rgba(0, 0, 0, 0);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    background-size: cover;
    padding-top: 300px;
    padding-bottom: 10px;
    padding-right: 1.28%;
    padding-left: 1.28%;
    position: relative;

    display: -ms-flexbox!important;
    display: flex!important;

    -ms-flex-align: center!important;
    align-items: center!important;

    .title{ 
        background-color:#000;
        color:#fff;
        font-size:28px;
        font-weight:600;
        
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
        margin:0 15px;

        padding:0 5px;
    }

    &:before,
    &:after{
        content:'';
        height: 2px;
        background: #000;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%;
    }
    &:before{
    }
    &:after{
    }
}

.btn.focus, .btn:focus {
    box-shadow: none;

}

.main-title{
    font-weight:600;
    text-align:center;
    font-size:1.5rem;
    margin-bottom:3rem;
}

.texts-custom{
    strong {
        font-weight: initial;
        font-weight:600;
    }
    h1,h2,h3,h4,h5,h6{

        color: #454545;
        transition: color 0.2s ease-in-out;
        letter-spacing: 0.2em;
        text-transform: uppercase;
    }
    h1{
        font-size: 24px;
    }

    h2{
        margin: 2.2em 0 0.9em;
        font-size: 22px;
    }
    h3{
        font-size: 20px;
        margin: 2.2em 0 1.2em;
    }
    p{
        font-size:17px;
        margin-bottom: 1.6em;
    }
}

.btn-group-toggle {
    label{
        border:solid black 1px;
        border-radius: 0;
        width:100%;
        
        input{
            position: absolute;
            clip: rect(0,0,0,0);
            pointer-events: none;
        }

    }

    .active{
    background-color:black!important;
    color:white;
    }
}

.custom-button-group-2 {
  
    
    label{
        border-bottom:solid #dadada 1px;
        width:100%;
        margin-bottom: 0!important;

        
        input{
            position: absolute;
            clip: rect(0,0,0,0);
            pointer-events: none;
        }

        .icon{
            width:16px;
            height:16px;
            border:solid #dadada 1px;
            border-radius: 100%; 

        }
        &.active{
            .icon{
                border-color:#535353;
                background-color:#535353;
                position: relative;
                border-radius: 100%; 
                
            }
            .icon:before{
                content:'';
                width:4px;
                height:4px;
                display: block;
                border-radius: 100%; 
                background-color:white;
                position: absolute;
            }

        }
        .price{
            font-weight:600;
            padding:0;
        }

        &.disabled > *{
            opacity:0.65;
        }
    }
    > *:last-child label{
        border-bottom:none;
    }
}

.customer-rewiews{
    .stars{
        img{
            margin:0 1px;
            height: 18px;
        }
    }
}


.custom-pagination{
    font-size:18px;
    line-height: 16px;
    >*.active{
        color:#000;
    }
    >*{
        padding: 5px 10px 5px 10px;
        color:#999;
        
        font-size: 16px;    
    }
    *:first-child,
    *:last-child{

    }
}

.chats{
    letter-spacing: 0;
    .user{
        font-size:12px;
        border-bottom:solid #f3f3f3 1px;
        padding-bottom:20px;
        &:last-child{
            border-bottom:none;
        }
        .user-header{
            margin-bottom: 10px;
            .profile-icon{
                width:45px;
                height:45px;
                background-color:#535353;
                color:white;
                font-size:16px;
                line-height: 45px;
                text-align: center;
                border-radius: 100%;
            }
            .name{
                padding-left:5px;
                font-weight:700;
                font-size:14px;

            }
            .date{
                font-size: 12px;
                color: #999;
                font-weight: 400;
                
            }
        }

        .comments-reviews{
            .icons{
                min-height: 22px;
                img{
                    height: 16px;
                }
                margin-right: 5px;
            }
            span{
                font-weight:700;
                color:#535353;
                font-size: 15px;
            }
        }
        .comments{
            font-size:16px;
            color:#666;
            letter-spacing: -0.5px;
            
        }
        .profile-image{
            img{
                border:solid #bebebe 1px;
                height:100px;
            }
        }
        .recomment{
            border-left:solid #dddddd 4px;
            margin-top:15px;
            padding:10px;
            padding-right:0px;
            .header{
                margin-bottom:5px;
                img{
                    height:50px;
                }
                .name{
                    padding-left:5px;
                    font-weight:700;
                    font-size:14px;
                    color:#535353;

                }
                .date{
                    font-size: 12px;
                    color: #999;
                    font-weight: 400;
                    
                }
            }

        }
    }
}


.comments-1{
    color:#3b3b3b;
    .item{
        padding:15px 20px;
 
        &:nth-last-child(2n){
            background-color:#f2f0f1;
        }

        .fas{
            font-size:24px;
            color:#dedede;
        }
        img{
        }
        .title{
            font-weight:700;
        }
        p{
            font-size:14px;
            margin-bottom:0;
        }
        .name{
            font-weight:600;
            padding:10px 0;
        }
        .photo{
            padding:0 30%;
            text-align:center;
            margin-bottom:10px;
        }
    }
}

.header-with-contact{
    background-color:whitesmoke;
    color:gray;
    font-size:14px;
    line-height: 18px;
    border-bottom: solid #dadada 1px;
}

.ingredients-2{
    color:#3b3b3b;
    background-color:#f0f0f0;
    .item{
        padding:15px 20px;
        margin-bottom:30px;
        background-color:white;
     
        img{
        }
        .title{
            font-weight:700;
        }
        p{
            font-size:15px;
            line-height: 20px;
        }
        .name{
            font-weight:600;
            padding:10px 0;
        }
        .photo{
            padding:15px 25%;
            text-align:center;
            margin-bottom:10px;
        }
        strong{
            font-weight: initial;
            font-weight:700;
            color:#666;
        }
    }
}

.risk-1{
    color:#3b3b3b;
    .item{
        
 
        .title{
            font-weight:700;
            text-align:center;

            font-size:17px;
            line-height: 28px;
            color:#888;
            margin-bottom:10px;
        }
        p{
            font-size:15px;
            line-height: 20px;
            text-align:center;
            color:#888;
        }
        .name{
            font-weight:600;
            padding:10px 0;
        }
        .photo{
            padding:15px 10%;
            padding-bottom:0;
            text-align:center;
            margin-bottom:10px;
        }
        strong{
            font-weight: initial;
            font-weight:700;
            color:#666;
        }
    }
}

.text-brand-green{
    color:#275f32!important;
}
